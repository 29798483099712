import React from "react";
import "./Checkout.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

function Checkout() {
    return (
        <Container className="mt-5">
            <Row>
                <Col md={8}>
                    <Card className="mb-4 p-4">
                        <h2>Checkout</h2>

                        {/* Billing Address */}
                        <h4 className="mt-4">Billing Address</h4>
                        <Form>
                            <Form.Group>
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" placeholder="John Doe" />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Address</Form.Label>
                                <Form.Control type="text" placeholder="1234 Main St" />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>City</Form.Label>
                                <Form.Control type="text" placeholder="City" />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>State</Form.Label>
                                <Form.Control type="text" placeholder="State" />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Zip Code</Form.Label>
                                <Form.Control type="text" placeholder="Zip" />
                            </Form.Group>
                        </Form>

                        <hr />

                        {/* Shipping Address */}
                        <h4>Shipping Address</h4>
                        <Form.Check type="checkbox" label="Same as billing address" />
                        {/* Assuming you'll use conditional rendering for showing the shipping address form fields based on the checkbox's state. */}

                        <hr />

                        {/* Payment Information */}
                        <h4>Payment Information</h4>
                        <Form>
                            <Form.Group>
                                <Form.Label>Card Name</Form.Label>
                                <Form.Control type="text" placeholder="Name on card" />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Card Number</Form.Label>
                                <Form.Control type="text" placeholder="XXXX-XXXX-XXXX-XXXX" />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Expiration Date</Form.Label>
                                <Form.Control type="text" placeholder="MM/YY" />
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>CVV</Form.Label>
                                <Form.Control type="text" placeholder="CVV" />
                            </Form.Group>
                        </Form>

                        <Button variant="success" size="lg" className="mt-4">Complete Purchase</Button>
                    </Card>
                </Col>

                {/* Cart Summary */}
                <Col md={4}>
                    <Card>
                        <Card.Body>
                            <Card.Title><h4>Order Summary</h4></Card.Title>
                            <Table striped bordered hover size="sm">
                                <thead>
                                    <tr>
                                        <th>Product</th>
                                        <th>Qty</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* Example entries, replace with actual cart items */}
                                    <tr>
                                        <td>Item 1</td>
                                        <td>2</td>
                                        <td>$20</td>
                                    </tr>
                                    <tr>
                                        <td>Item 2</td>
                                        <td>1</td>
                                        <td>$10</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <h5 className="text-right">Total: $50</h5>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Checkout;
