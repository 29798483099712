import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./SignUpConfirmation.css";

function SignUpConfirmation() {
  return (
    <Container id="confirm">
      <Container id="confirm-contain">
        <Row className="justify-content-center">
          <Col md={6} className="text-center">
            <h2>Thank You for Signing Up!</h2>
            <p className="mt-4">
              We're excited to have you onboard. An email confirmation has been
              sent to your provided address. Please follow the instructions in the
              email to complete your registration.
            </p>
            <Button className="mt-4" variant="primary" href="/sign-in">
              signin
            </Button>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default SignUpConfirmation;
