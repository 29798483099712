import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Profile from "./Pages/Profile/Profile";
import Cart from "./Pages/Cart/Cart";
import Checkout from "./Pages/Checkout/Checkout";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Pages/Home/Home";
import ContactUs from "./Pages/ContactUs/ContactUs";
import SignUp from "./Pages/SignUp/SignUp";
import SignIn from "./Pages/SignIn/SignIn";
import SignUpConfirmation from "./Pages/SignUpConfirmation/SignUpConfirmation";
import AboutUs from "./Pages/AboutUs/AboutUs";
import BackToTopButton from "./components/BackToTopButton/BackToTopButton";
import AddProducts from "./Pages/Products/AddProducts";
import GetProducts from "./Pages/Products/GetProducts";
import UpdateProducts from "./Pages/Products/UpdateProducts";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import AccessDenied from "./Pages/AccessDenied /AccessDenied";

function App() {
  return (
    <div className="app-container">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/sign-up-confirmation" element={<SignUpConfirmation />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/add-products" element={<AddProducts />} />
          <Route path="/get-products" element={<GetProducts />} />
          <Route path="/update-products/:id" element={<UpdateProducts />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/access-denied" element={<AccessDenied />} />
        </Routes>
        <div>
          <Footer />
        </div>
      </Router>
      <BackToTopButton />
    </div>
  );
}

export default App;
