import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faEdit } from "@fortawesome/free-solid-svg-icons";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from "react-toastify";
import '../Products/Products.css';
import ReactPaginate from "react-paginate";

function GetProducts() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 10;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userId = localStorage.getItem("User Id", );
        const isAdmin = localStorage.getItem("isAdmin");

        console.log("UserId from localStorage:", userId);
        console.log("IsAdmin from localStorage:", isAdmin);

      if (!userId || isAdmin !== "true") {
        console.log("Access Denied - Redirecting to access-denied");
        navigate("/access-denied");
        return; 
      }
        const response = await Axios.get(
          "http://phpcms.viewmynew.com/products/get_products"
        );
  
        if (response.data && response.data.status === "success") {
          setProducts(response.data.data || []);
        } else {
          
          console.error("Error getting products", response.data);
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };
  
    fetchData();
  }, [navigate]);
  
  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this product?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await deleteProduct(id);
              toast.success("Product deleted successfully");
            } catch (error) {
              toast.error(`Error deleting product: ${error.message}`);
            }
          },
        },
        {
          label: "Cancel",
          onClick: () => {
            console.log("Deletion cancelled");
          },
        },
      ],
    });
  };
  const deleteProduct = async (id) => {
    try {
      setProducts((prevProducts) =>
        prevProducts.filter((product) => product.id !== id)
      );

      const response = await Axios.delete(
        `http://phpcms.viewmynew.com/products/delete_products/?id=${id}`
      );
      if (response.status !== 200) {
        throw new Error(response.data.message || "Error deleting product");
      }
    } catch (error) {
      toast.error(`Error deleting product: ${error.message}`);
      throw error;
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  // Filtering products based on search term
  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const subset = filteredProducts.slice(startIndex, endIndex);
  // Function to handle search term change
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(0); // Reset pagination when search term changes
  };
  return (
    <div className="container mt-5" id="view-inventory">
      <h1>Welcome to the Product Inventory</h1>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search by product name"
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
      </div>

      <div className="table-container">
        <table className="custom-table">
          <thead>
            <tr>
              <th>Product Id</th>
              <th>Product Name</th>
              <th>Price</th>
              <th>Quantity in Stock</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {subset.map((product) => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.name}</td>
                <td>{product.price}</td>
                <td>{product.quantity}</td>
                <td>
                  <img
                    src={`http://phpcms.viewmynew.com/${product.image}`}
                    alt={product.name}
                    style={{
                      maxWidth: "100px",
                      maxHeight: "100px",
                      display: "block",
                      margin: "0 auto",
                    }}
                  />
                </td>
                <td>
                  <FontAwesomeIcon
                    onClick={() => handleDelete(product.id)}
                    icon={faTrashCan}
                    style={{ cursor: "pointer" }}
                  />

                  <Link to={`/update-products/${product.id}`}>
                    <FontAwesomeIcon
                      icon={faEdit}
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                    />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        pageCount={Math.ceil(filteredProducts.length / itemsPerPage)}
        onPageChange={handlePageChange}
        forcePage={currentPage}
        containerClassName={"pagination"}
        activeClassName={"active"}
      />

      <Link
        to="/add-products"
        onClick={() => navigate("/add-products")}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          textDecoration: "none",
        }}
      >
        <button
          style={{
            borderRadius: "8px",
            padding: "10px 20px",
            cursor: "pointer",
            marginBottom: "10px",
          }}
        >
          Add more products
        </button>
      </Link>
    </div>
  );
}
export default GetProducts;