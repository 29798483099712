import React from "react";
import "../../App.css";
import "./MainCTA.css";

function MainCTA() {
  return (
    <>
      <div className="container px-5 py-2" id="main-section">
        {/* <h2 className="pb-2 border-bottom">Main Call to Action Section</h2> */}

        <div className="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-3">
          <div className="col">
            <div className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg">
              <div className="d-flex flex-column h-100 p-5 pb-3  text-shadow-1">
                <h3 className="cta-h pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  CTA
                </h3>
                <a href="/" className="icon-link">
                  Call to action
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg">
              <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                <h3 className="cta-h pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  CTA
                </h3>
                <a href="/" className="icon-link">
                  Call to action
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg">
              <div className="d-flex flex-column h-100 p-5 pb-3 text-shadow-1">
                <h3 className="cta-h pt-5 mt-5 mb-4 display-6 lh-1 fw-bold">
                  CTA
                </h3>
                <a href="/" className="icon-link">
                  Call to action
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainCTA;
