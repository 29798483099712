import React from "react";
import "./Testimonials.css";
import Carousel from "react-bootstrap/Carousel";

function Testimonials() {
  return (
    <>
      <Carousel
        data-bs-theme="dark"
        className="carousel slide py-3"
        id="review-carousel"
        data-bs-ride="true"
      >
        <Carousel.Item>
          <div className="container py-3" id="review-section">
            <div className="my-3 p-3 rounded shadow-sm">
              <h3 className="border-bottom pb-2 mb-0">Recent Reviews</h3>
              <div className="d-flex text-body-secondary pt-3">
                <p className="pb-3 mb-0 small lh-sm border-bottom">
                  <strong className="d-block text-gray-dark">
                    Customer Doe
                  </strong>
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua."
                </p>
              </div>
              <div className="d-flex text-body-secondary pt-3">
                <p className="pb-3 mb-0 small lh-sm border-bottom">
                  <strong className="d-block text-gray-dark">
                    Customer Doe
                  </strong>
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.ut labore et dolore magna aliqua."
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="container py-3" id="review-section">
            <div className="my-3 p-3 rounded shadow-sm">
              <h3 className="border-bottom pb-2 mb-0">Recent Reviews</h3>
              <div className="d-flex text-body-secondary pt-3">
                <p className="pb-3 mb-0 small lh-sm border-bottom">
                  <strong className="d-block text-gray-dark">
                    Customer Doe
                  </strong>
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua."
                </p>
              </div>
              <div className="d-flex text-body-secondary pt-3">
                <p className="pb-3 mb-0 small lh-sm border-bottom">
                  <strong className="d-block text-gray-dark">
                    Customer Doe
                  </strong>
                  "Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna
                  aliqua.ut labore et dolore magna aliqua."
                </p>
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
}

export default Testimonials;
