import React from "react";
import "./MainTextFeature.css";
import img_stock from "../../images/img_stock.png";

function MainTextFeature() {
  return (
    <>
      <div className="container col px-5 py-3" id="main-text-section">
        <div className="row flex-lg-row-reverse align-items-center g-5 px-3">
          <div className="col-10 col-sm-8 col-lg-6">
            <img
              src={img_stock}
              className="d-block mx-lg-auto img-fluid rounded-4 shadow-lg"
              alt="stock"
              width="400"
              height="300"
              loading="lazy"
            />
          </div>
          <div className="col-lg-6 px-5">
            <h3 className="fw-bold text-body-emphasis lh-1 mb-1">
              Main Secondary Text Section
            </h3>
            <p className="lead">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Non
              sodales neque sodales ut etiam sit amet nisl purus. Metus dictum
              at tempor commodo ullamcorper a lacus vestibulum sed.
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-start">
              <button
                type="button"
                className="btn btn-small btn-primary px-4 me-md-2"
              >
                Primary
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainTextFeature;
