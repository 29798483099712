import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FaEnvelope, FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import "./Footer.css";
import adsol_map_img from "../../images/adsol_map_img.png";

function Footer() {
  return (
    <div className="footer">
      <Container>
        <Container className="footer-top">
          <h2 className="footer-name">Company Name</h2>
          <p className="footer-desc">Open Monday - Friday - 8am - 5pm</p>
          <Nav defaultActiveKey="/" className="flex-row custom-nav">
            <Nav.Link className="footer-link" href="/">
              Home
            </Nav.Link>
            <Nav.Link className="footer-link" href="/contact-us">
              Contact Us
            </Nav.Link>
            <Nav.Link className="footer-link" href="/sign-in">
              Sign In
            </Nav.Link>
          </Nav>
        </Container>
        <Row className="align-items-center">
          <Col md={4} className="text-center">
            <h2 className="mt-4">Newsletter SignUp</h2>
            <Form className="newsletter-signup">
              <Form.Control
                type="email"
                placeholder="Email"
                className="mb-2 mr-sm-2"
              />
              <Button variant="outline-light" type="submit" className="mb-2" id="news-button">
                SignUp
              </Button>
            </Form>
          </Col>
          <Col md={4}>
            <h3>Contact Info</h3>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <FaMapMarkerAlt className="mr-2" /> 1234 Street Name, City Name,
                Country Name
              </ListGroup.Item>
              <ListGroup.Item>
                <FaPhoneAlt className="mr-2" /> +1 123 456 7890
              </ListGroup.Item>
              <ListGroup.Item>
                <FaEnvelope className="mr-2" /> example@email.com
              </ListGroup.Item>
            </ListGroup>
          </Col>

          <Col md={4} className="text-center">
            <img src={adsol_map_img} alt="map" className="footer-image" />
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-4">
            <p>© 2023 Company Name. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Footer;
