import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import "./Products.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateProducts() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    product_name: "",
    product_price: "",
    product_image: "",
    existingImage: "",
    product_quantity: 0,
  });

  useEffect(() => {
    const checkUserAccess = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("User Id"));
        const userPrivilege = localStorage.getItem("User Privilege");

        if (!user || userPrivilege !== "admin") {
          navigate("/access-denied"); // Redirect to access-denied if not signed in or not admin
        } else {
          // If the user is an admin, proceed to fetch product details
          try {
            const response = await Axios.get(
              `http://phpcms.viewmynew.com/products/get_products/?id=${id}`
            );

            console.log("Full response:", response);

            if (response.status === 200) {
              // const responseData = JSON.parse(response.data.trim().substring(2));
              console.log("Parsed Response:", response.data);

              if (response.data.status === "success") {
                const products = response.data.data; // Assuming responseData.data is an array of products
                const foundProduct = products.find(
                  (p) => p.id === parseInt(id, 10)
                );

                if (foundProduct) {
                  setProduct({
                    product_name: foundProduct.name,
                    product_price: foundProduct.price,
                    product_image: foundProduct.image,
                    existingImage: foundProduct.image,
                    product_quantity: foundProduct.quantity || 0,
                  });
                } else {
                  console.error("Product with the specified ID not found");
                }
              } else {
                console.error("Error fetching product:", response.data.error);
              }
            } else {
              console.error(
                "Error fetching product. Unexpected status:",
                response.status
              );
            }
          } catch (error) {
            console.error("Error fetching product:", error);
          }
        }
      } catch (error) {
        console.log("Error:", error);
      }
    };

    checkUserAccess();
  }, [id, navigate]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      formData.append('product_id', id);
      formData.append("product_name", product.product_name);
      formData.append("product_price", product.product_price);
      formData.append("product_image", product.product_image);
      formData.append("product_quantity", product.product_quantity); 


      console.log("Form Data:", formData);

      const response = await Axios.post(
        `http://phpcms.viewmynew.com/products/update_products/${id}`, formData
      );
      if (response.status === 200) {
        console.log("Product updated successfully");
        navigate('/get-products')
      } else {
        console.error("Error updating product");
      }
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProduct({ ...product, [name]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const fileName = file.name;

      setProduct({
        ...product,
        product_image: file,
        existingImage: fileName,
      });
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Update Products</h2>
      <form onSubmit={handleSubmit} className="complex-form">
        <div className="form-group mb-3">
          <label htmlFor="product_name" className="form-label">
            Product Name
          </label>
          <input
            type="text"
            id="product_name"
            name="product_name"
            value={product.product_name}
            onChange={handleInputChange}
            className="form-control"
            placeholder="Enter product name"
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="product_price" className="form-label">
            Product Price
          </label>
          <input
            type="text"
            id="product_price"
            name="product_price"
            value={product.product_price}
            onChange={handleInputChange}
            className="form-control"
            placeholder="Enter product price"
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="product_quantity" className="form-label">
            Product Quantity
          </label>
          <input
            type="number"
            id="product_quantity"
            name="product_quantity"
            value={product.product_quantity}
            onChange={handleInputChange}
            className="form-control"
            placeholder="Enter product quantity"
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="product_image" className="form-label">
            Current Image
          </label>
          <div className="existing-image-container">
            {product.product_image && (
              <>
                <p>{product.existingImage}</p>
                {product.product_image instanceof File ? (
                  <img
                    src={URL.createObjectURL(product.product_image)}
                    alt="Existing Thumbnail"
                    className="existing-thumbnail"
                  />
                ) : (
                  <img
                    src={`http://phpcms.viewmynew.com/${product.product_image}`}
                    alt="Existing Thumbnail"
                    className="existing-thumbnail"
                  />
                )}
              </>
            )}
          </div>
        </div>
        <div className="form-group mb-3">
          <label htmlFor="product_image" className="form-label">
            New Image
          </label>
          <input
            type="file"
            id="product_image"
            name="product_image"
            onChange={handleImageChange}
            className="form-control-file"
          />
        </div>

        <div className="form-group">
          <button type="submit" className="btn btn-success">
            Update Product
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default UpdateProducts;
