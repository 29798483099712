import React, { useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import "./SignIn.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function SignIn() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSigninSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await Axios.post(
        `https://phpcms.viewmynew.com/user/signin`,
        formData,
        { withCredentials: true }
      );

      console.log("This is the entire response from the server: " , response);
      console.log("type of primitive that response is: " , typeof response);
      console.log("What is the type of response.data" , typeof response.data);

      // Check if the response data is a string
      // if (typeof response.data === 'string') {
      //   // Remove unnecessary characters (e.g., comments and newlines) from the response data
      //   const cleanResponseData = response.data.replace(/\/\/.*?\n/g, '').trim();

      //   console.log("The cleaned up response.data is" , cleanResponseData);

      //   // Attempt to parse the JSON content from the cleaned response data
      //   try {
      //     const responseData = JSON.parse(cleanResponseData);
      //     response.data = responseData;
      //     console.log("After parsing the response.data into JSON, we get: " , response.data);
      //   } catch (parseError) {
      //     console.error("Error parsing response data:", parseError);
      //   }
      // }

      //In the case when response.data is not a clean object, this will extract the object and clean it up for use.
      if (typeof response.data === 'string') {
        // Regular expression to find a JSON object
        const jsonObjectRegex = /\{.*\}/s;
    
        // Extract JSON object
        const jsonObjectMatch = response.data.match(jsonObjectRegex);
        if (jsonObjectMatch) {
            const cleanResponseData = jsonObjectMatch[0];
    
            console.log("The extracted JSON object is", cleanResponseData);
    
            // Attempt to parse the JSON content
            try {
                const responseData = JSON.parse(cleanResponseData);
                response.data = responseData;
                console.log("After parsing the JSON object, we get: ", response.data);
            } catch (parseError) {
                console.error("Error parsing response data:", parseError);
            }
        } else {
            console.error("No JSON object found in the response data");
        }
      }
    

      if (response.status === 200 && response.data && response.data.status === "success") {
        const { id, username, privilege} = response.data;
        console.log("privilege in response.data: " , privilege);
        
        localStorage.setItem("User Id", id);
        
        if (username) {
          localStorage.setItem("Username", username);
        }
        // if (privilege) {
        //   //we should not save this here
        //   localStorage.setItem("User Privilege", privilege);
        // }
        
        if (privilege === "admin") {
          navigate("/admin-dashboard");
        } else {
        navigate("/profile");
        }

      } else {
        console.error("Sign In Failed:", response.data.message);
        // Handle the error or show a message to the user
      }
    } catch (error) {
      console.error("An error has occurred:", error);

      if (error.response) {
        console.error("Server response status:", error.response.status);
        console.error("Server response data:", error.response.data);
        alert("Server error: " + (error.response.data.message || "Unknown error"));
      } else {
        console.error("Error details:", error.message);
        alert("An error has occurred. Please check the console for details.");
        console.error("Full error object:", error);
      }
    }
  };

  return (
    <Container className="" id="sign-in">
      <Row className="" id="sign-in-child">
        <Col md={6}>
          <Form onSubmit={handleSigninSubmit} id="border">
            <h2>Sign In</h2>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                name="email"
                type="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                autoComplete="email"
                required
              />
              <Form.Text className="text-muted">
                We'll never share your email with anyone else.
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                Please enter a valid email address.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                name="password"
                type="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                autoComplete="current-password"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid password.
              </Form.Control.Feedback>
            </Form.Group>

            <Button variant="primary" type="submit">
              Sign In
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default SignIn;

