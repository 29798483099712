import React from "react";

function MainFeature() {
  return (
    <React.Fragment>
      <div className="container col-xxl-10 px-2 py-3">
        <div className="row flex-lg-row align-items-start">
          <div className="col-lg-6">
            <h3 className="fw-bold text-body-emphasis lh-1 mb-1">Headlines</h3>
            <p className="lead">
              Quickly design and customize responsive mobile-first sites with
              Bootstrap, the most popular front-end open source toolkit,
              featuring Sass variables and mixins, responsive grid system,
              extensive prebuilt components, and powerful JavaScript plugins.
            </p>
          </div>
          <div className="col-lg-6">
            <h3 className="fw-bold text-body-emphasis lh-1 mb-1">
              Another snazzy headline
            </h3>
            <p className="lead">
              Quickly design and customize responsive mobile-first sites with
              Bootstrap, the most popular front-end open source toolkit,
              featuring Sass variables and mixins, responsive grid system,
              extensive prebuilt components, and powerful JavaScript plugins.
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MainFeature;
