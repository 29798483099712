import React from "react";
import CarouselComponent from "../../components/Carousel/Carousel";
import MainFeature from "../../components/MainFeature/MainFeature";
import MainCTA from "../../components/MainCTA/MainCTA";
import MainTextFeature from "../../components/MainTextFeature/MainTextFeature";
import Testimonials from "../../components/Testimonials/Testimonials";

function Home() {
  return (
    <>
      <CarouselComponent />
      <MainFeature />
      <MainCTA />
      <MainTextFeature />
      <Testimonials />
    </>
  );
}

export default Home;
