import React from "react";
import "../../index.css";
import "./Header.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../images/logo.jpeg";

function Header() {
  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        className="navbar navbar-expand-md bg-light justify-content-between"
        id="navbar"
      >
        <Container fluid>
          <Navbar.Brand href="/">
            {" "}
            <img src={logo} height={40} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="navbbasic-navbar-navrScroll">
            <div className="mx-auto">
              <Nav className="navbar-nav me-auto mb-2 mb-md-0">
                <Nav.Link href="/">Home</Nav.Link>
                <Nav.Link href="/about-us">About Us</Nav.Link>

                <NavDropdown title="Account" id="basic-navbar-dropdown">
                  <NavDropdown.Item href="/sign-in">Sign In</NavDropdown.Item>
                  <NavDropdown.Item href="/sign-up">Sign Up</NavDropdown.Item>
                  <NavDropdown.Item href="/profile">
                    User Profile
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#">
                    Something else here
                  </NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Shop Admin" id="basic-navbar-dropdown">
                  <NavDropdown.Item href="/add-products">Add Products</NavDropdown.Item>
                  <NavDropdown.Item href="/get-products">View Products</NavDropdown.Item>
                  <NavDropdown.Item href="/admin-dashboard">Admin Dashboard </NavDropdown.Item>

                  
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#">
                    Something else here
                  </NavDropdown.Item>
                </NavDropdown>

                <Nav.Link href="/contact-us">Contact Us</Nav.Link>
                <Nav.Link href="/checkout">Checkout</Nav.Link>

                <Nav.Link href="/cart">Cart</Nav.Link>
                <Nav.Link href="/sign-up-confirmation">
                  Sign Up Confirmation
                </Nav.Link>
              </Nav>
            </div>
            <Form className="d-flex p-2">
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
              />
              <Button variant="btn btn-primary">Search</Button>
            </Form>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
}

export default Header;
