import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';

const AccessDenied = () => {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="access-denied-page">
      {/* Button to open the modal */}
      <button onClick={() => setModalIsOpen(true)} style={{ display: 'none' }}>Open Modal</button>

      {/* Modal for displaying the access denied message */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Access Denied Modal"
        ariaHideApp={false} // Prevents a11y warnings in development mode
      >
        <h2>Access Denied</h2>
        <p>You do not have permission to access this page.</p>
        <p>Please <Link to="/sign-in">sign in</Link> or <Link to="/sign-up">create a profile</Link>.</p>
        <button onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default AccessDenied;