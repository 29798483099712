import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import SignIn from '../SignIn/SignIn.js';
// import { faAlignCenter } from "@fortawesome/free-solid-svg-icons";


function Profile() {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    mobile: "",
    address: "",
    paymentMethod: "",
  });

   // PLACEHOLDER for adding condition to check if the user is logged in - react context??
   const isSignedIn = true; // Replace with our authentication logic

   

  useEffect(() => {
    const getUser = async () => {
      const id = localStorage.getItem('User Id');
      console.log(id);

      if (id) {
        try {
          const response = await Axios.get(
            `https://phpcms.viewmynew.com/user/profile/?id=${id}`
          );
          console.log(response);
          if (typeof response.data === 'string') {
            // Remove unnecessary characters (e.g., comments and newlines) from the response data
            const cleanResponseData = response.data.replace(/\/\/.*?\n/g, '').trim();
            

            // Attempt to parse the JSON content from the cleaned response data
            try {
              const responseData = JSON.parse(cleanResponseData);
              response.data = responseData;
            } catch (parseError) {
              console.error("Error parsing response data:", parseError);
            }
          }



          if (response.status === 200) {
            setFormData(response.data.data);
          } else {
            console.error("Error getting profile info:", response.data.message);
          }
        } catch (error) {
          console.error("Error getting profile info", error);
        }
      } else {
        // Handle error - maybe redirect to sign-in page?
      }
    };
    getUser();
  }, []);

  const handleLogout = () => {
    // Clear user-related data from local storage
    localStorage.removeItem('User Id');
    localStorage.removeItem('User Token');

    // Navigate to the sign-in page
    navigate("/signIn");
  };

  
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsEditing(false);
    try {
      const id = localStorage.getItem('User Id');
      const passwordResponse = await Axios.get(`https://phpcms.viewmynew.com/user/check_email`, formData);
      if (typeof passwordResponse.data === 'string') {
        // Remove unnecessary characters (e.g., comments and newlines) from the response data
        const cleanResponseData = passwordResponse.data.replace(/\/\/.*?\n/g, '').trim();

        // Attempt to parse the JSON content from the cleaned response data
        try {
          const responseData = JSON.parse(cleanResponseData);
          passwordResponse.data = responseData;
        } catch (parseError) {
          console.error("Error parsing response data:", parseError);
        }
      }
      if (passwordResponse.data.status === "error" || passwordResponse.data.message === "Email already exists") {
        const changeEmail = window.confirm("this email is taken please use a different email.");
        if (changeEmail) {
          // Clear the email field
          setFormData({ ...formData, email: "" });
          return;
        } else {
          // Redirect the user to the sign-in page
          navigate("/sign-up");
          return;
        }

  }

  const response = await Axios.put('https://phpcms.viewmynew.com/user/updateProfile',
    {
      ...formData,
      id: id  // sending the user id along with formData
    }
  );

  console.log('Update Profile Response:', response);

  if (response.status === 200) {
    console.log('Profile updated successfully!');
  } else {
    console.error('Failed to update profile:', response.data.message);
  }
} catch (error) {
  console.error('Error updating profile:', error);
  console.log('Error Response:', error.response);

  // Detailed error logging
  if (error.response) {
    console.log('Error Response Data:', error.response.data);
    console.log('Error Response Status:', error.response.status);
    console.log('Error Response Headers:', error.response.headers);
  } else if (error.request) {
    console.log('Error Request:', error.request);
  } else {
    console.log('Other Error:', error.message);
  }
};
};

const handleDelete = async () => {
  try {
    // Prompt the user for their password
    const userPassword = prompt('Please enter your password for verification:');

    if (userPassword === null) {
      // User canceled the password prompt
      console.log('Profile deletion canceled.');
      return;
    }

    const id = localStorage.getItem('User Id');

    // Send a request to verify the password
    const verifyPasswordResponse = await Axios.post(
      `https://phpcms.viewmynew.com/user/verify_password`,
      { id, password: userPassword }
    );
    if (typeof verifyPasswordResponse.data === 'string') {
      // Remove unnecessary characters (e.g., comments and newlines) from the response data
      const cleanResponseData = verifyPasswordResponse.data.replace(/\/\/.*?\n/g, '').trim();

      // Attempt to parse the JSON content from the cleaned response data
      try {
        const responseData = JSON.parse(cleanResponseData);
        verifyPasswordResponse.data = responseData;
      } catch (parseError) {
        console.error("Error parsing response data:", parseError);
      }
    }

    if (verifyPasswordResponse.status === 200) {
      // Password verification successful, proceed with profile deletion
      const deleteResponse = await Axios.delete(
        `https://phpcms.viewmynew.com/user/delete_user/?id=${id}`
      );

      if (deleteResponse.status === 200) {
        navigate('/Sign-up');
      } else {
        console.error('Failed to delete profile:', deleteResponse.data.message);
      }
    } else {
      // Password verification failed
      console.error('Password verification failed:', verifyPasswordResponse.data.message);
    }
  } catch (error) {
    console.error('Error deleting profile:', error);
    // Detailed error logging...
  }
};

// If the user is not logged in, render the login page
//This (isSignedIn) is a placeholder for react context in the future - wrap app in authentication context?
if (!isSignedIn) {
  return (
    <div>
      <br/>
      <h1>Sign in to access your profile.</h1>
      <SignIn />
    </div>
  );
}

return (
  <div className="container mt-5" id="profile">
    <h1>Welcome, {formData.username}!</h1>
    <Card className="mx-auto profile-card">
      {isEditing ? (
        <Form onSubmit={handleSubmit}>
          <Card.Body>
            <Card.Title>Edit Profile</Card.Title>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formBasicUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                value={formData.username}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formBasicAddress">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formBasicMobile">
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                type="text"
                name="mobile"
                value={formData.mobile}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formBasicPaymentMethod">
              <Form.Label>Payment Method</Form.Label>
              <Form.Control
                type="text"
                name="paymentMethod"
                value={formData.paymentMethod}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Card.Body>
          <Card.Footer>
            <Button type="submit">Save Changes</Button>
            <Button
              variant="secondary"
              className="ml-2"
              onClick={() => setIsEditing(false)}
            >
              Cancel
            </Button>
          </Card.Footer>
        </Form>
      ) : (
        <Card.Body>
          <Card.Title>Profile Details</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">
            {formData.email}
          </Card.Subtitle>
          <Card.Text>
            Email: {formData.email} <br />
            Address: {formData.address} <br />
            Mobile: {formData.mobile} <br />
            Payment Method: {formData.paymentMethod}
          </Card.Text>
          <Button className="mt-2" onClick={() => setIsEditing(true)}>
            Edit Profile
          </Button>
          <Button
            variant="danger"
            className="ml-2"
            onClick={handleDelete}
          >
            Delete Account
          </Button>

        </Card.Body>
      )}
    </Card>

    <Button className="mt-3" onClick={handleLogout}>
      Log Out
    </Button>
  </div>
);
}
export default Profile;