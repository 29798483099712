import React, { useState, useEffect } from "react";
import "./Products.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

function AddProducts() {
  const [productData, setProductData] = useState({
    product_name: "",
    product_price: "",
    product_image: null,
    product_quantity: 0,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const checkUserAccess = async () => {
      try {
        const userId = localStorage.getItem("User Id");
        const privilege = localStorage.getItem("User Privilege");

        if (!userId || privilege !== "admin") {
          // Redirect to sign-in page if the user is not logged in or doesn't have admin privilege
          navigate("/access-denied");
        }
      } catch (error) {
        console.error("Error checking user access:", error);
        // Handle error or show a message to the user
      }
    };

    checkUserAccess();
  }, [navigate]);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProductData({ ...productData, [name]: value });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setProductData({ ...productData, product_image: file });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("add_product", true);
    formData.append("product_name", productData.product_name);
    formData.append("product_price", productData.product_price);
    formData.append("product_image", productData.product_image);
    formData.append("product_quantity", productData.product_quantity);


    // Make a POST request to your PHP backend script
    Axios.post("http://phpcms.viewmynew.com/products/add_products", formData,
      { withCredentials: true })
      // Axios.post("http://localhost:8888/newcms/products/add_products", formData) 
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        if (data === "Product inserted Successfully") {
          setProductData({
            product_name: "",
            product_price: "",
            product_image: null,
            product_quantity: 0,
          });
          toast.success(data, { autoClose: 3000, className: "success-toast" });
        } else {
          navigate("/get-products");
          toast.error(data, { autoClose: 3000 });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Network Error", { autoClose: 3000 });
      });
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Add Product to Inventory</h2>
      <form onSubmit={handleSubmit} className="complex-form">
        <div className="form-group mb-3">
          <label htmlFor="product_name" className="form-label">
            Product Name
          </label>
          <input
            type="text"
            id="product_name"
            name="product_name"
            value={productData.product_name}
            onChange={handleInputChange}
            className="form-control"
            placeholder="Enter product name"
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="product_price" className="form-label">
            Product Price
          </label>
          <input
            type="text"
            id="product_price"
            name="product_price"
            value={productData.product_price}
            onChange={handleInputChange}
            className="form-control"
            placeholder="Enter product price"
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="product_quantity" className="form-label">
            Quantity
          </label>
          <input
            type="number"
            id="product_quantity"
            name="product_quantity"
            value={productData.product_quantity}
            onChange={handleInputChange}
            className="form-control"
            placeholder="Enter quantity"
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="product_image" className="form-label">
            Product Image
          </label>
          <input
            type="file"
            id="product_image"
            name="product_image"
            onChange={handleImageChange}
            className="form-control-file"
          />
        </div>
        <div className="form-group">
          <button type="submit" className="btn btn-success">
            Add Product
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
}

export default AddProducts;




