import React, { useState, useEffect } from 'react';
import './BackToTopButton.css';

function BackToTopButton() {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const buttonClass = `back-to-top ${visible ? 'visible' : ''}`;


    return (
      <div className={buttonClass} onClick={scrollToTop}>
        ↑
      </div>
    );
}

export default BackToTopButton;
