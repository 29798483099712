import React, { useState } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../index.css";
import "./SignUp.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SignUp = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    address: "",
    mobile: "",
    paymentMethod: "",
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSignUpSubmit = async (e) => {
    e.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      alert("Passwords do not match");
      return;
    }

    // try {
    // Check if the username and email are provided
    if (!formData.username || !formData.email) {
      alert("Please provide both username and email.");
      return;
    }

    const response = await Axios.get(`https://phpcms.viewmynew.com/user/check_email`, formData);
    if (typeof response.data === 'string') {
      // Remove unnecessary characters (e.g., comments and newlines) from the response data
      const cleanResponseData = response.data.replace(/\/\/.*?\n/g, '').trim();

      // Attempt to parse the JSON content from the cleaned response data
      try {
        const responseData = JSON.parse(cleanResponseData);
        response.data = responseData;
      } catch (parseError) {
        console.error("Error parsing response data:", parseError);
      }
    }
    if (response.data.status === "error" || response.data.message === "Email already exists") {
      const changeEmail = window.confirm("The email is already taken. Do you want to change your email or sign in?");
      if (changeEmail) {
        // Clear the email field
        setFormData({ ...formData, email: "" });
        return;
      } else {
        // Redirect the user to the sign-in page
        navigate("/sign-up");
        return;
      }

    }
    const signUpResponse = await Axios.post(`https://phpcms.viewmynew.com/user/save`, formData); {

      if (signUpResponse.status === 200) {
        // Navigate to the confirmation page only if there is no error
        navigate("/sign-up-confirmation");
      } else {
        console.error("Unexpected response:", response.data); // Log unexpected responses for debugging
        alert("An unexpected error has occurred");
      }
      // catch (error) {
      //   console.error("Error during sign-up:", error); // Log errors for debugging
      //   alert("An unexpected error has occurred: " + error.message);
    }
  };

  return (
    <Container className="" id="sign-up">
      <Row id="sign-up-child">
        <Col md={6}>
          <Form onSubmit={handleSignUpSubmit} id="border">
            <h2>Sign Up</h2>

            <Form.Group controlId="formBasicEmail">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="text"
                name="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                autoComplete="new-password" // Use "new-password" for password fields
              />
            </Form.Group>

            <Form.Group controlId="formBasicConfirmPassword">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
                autoComplete="new-password" // Use "new-password" for confirmation fields
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Sign Up
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SignUp;
