import React, {useState} from "react";
import "./Cart.css";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";

function Cart() {
  const [cartItems, setCartItems] = useState ([
    {
      id: 1,
      title: "Product 1",
      seller: "Seller A",
      price: 10.0,
      qty: 1,
      image: "path_to_image1.jpg",
    },
    {
      id: 2,
      title: "Product 2",
      seller: "Seller B",
      price: 15.0,
      qty: 2,
      image: "path_to_image2.jpg",
    },
  ]);

  const getTotalPrice = () => {
    return cartItems
      .reduce((acc, item) => acc + item.price * item.qty, 0)
      .toFixed(2);
  };

  const handleIncreaseQuantity = (itemId) => {
    const updatedCart = cartItems.map((item)=> {
      if (item.id === itemId) {
        return { ...item, qty: item.qty + 1};
      }
      return item;
      });

      setCartItems(updatedCart);
      
    };

    const handleDecreaseQuantity = (itemId) => {
      const  updatedCart = cartItems.map((item) => {
        if (item.id === itemId && item.qty >1) {
          return { ...item, qty: item.qty - 1 };
        }
        return item;

      });
      setCartItems(updatedCart);
    };

  const handleRemoveItem = (itemId) => {
    const updatedCart = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedCart);
   }
;
return (
    <>
      <div className="cart-container" id="cart-main">
        <h2 className="cart-header">Your Cart</h2>
        {cartItems.map((item) => (
          <Card key={item.id} className="cart-card mb-3">
            {/* Here, you can add an image if needed using <Card.Img /> */}
            <Card.Body className="cart-card-body">
              <Card.Title className="cart-card-title">{item.title}</Card.Title>
              <Card.Text className="cart-card-text">
                Price: ${item.price.toFixed(2)}
              </Card.Text>
              <Card.Text className="cart-card-text">
                Quantity: {item.qty}
                <Button
                  variant="outline-primary"
                  size="sm"
                  className="ml-2"
                  onClick={() => handleIncreaseQuantity(item.id)}
                >
                  +
                </Button>
                <Button
                  variant="outline-danger"
                  size="sm"
                  className="ml-2"
                  onClick={() => handleDecreaseQuantity(item.id)}
                >
                  -
                </Button>

              </Card.Text>
            </Card.Body>
            <Button variant="danger" onClick={() => handleRemoveItem(item.id)}>Remove Item</Button>
          </Card>
        ))}

        <ListGroup variant="flush">
          <ListGroup.Item className="cart-summary-item">
            Subtotal: ${getTotalPrice()}
          </ListGroup.Item>
          {/* You can add more summary items like tax, shipping, etc. */}
          <ListGroup.Item className="cart-summary-item">
            <Link to="/checkout">
              <Button variant="primary" className="cart-btn" >
                Proceed to Checkout
              </Button>
            </Link>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </>
  );
}

export default Cart;
