import './AboutUs.css';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import blueberries from '../../images/blueberries.jpeg';

function AboutUs() {
  return (
      <Container className="mt-5">
          <Row className="justify-content-center">
              <Col md={6} className="text-center">
                  <h2>About Us</h2>
                  <p className="mt-4">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum. Donec in efficitur leo. Integer nec odio praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa.
                  </p>
              </Col>
              <Col md={6} className="text-center">
                  <Image src={blueberries} alt="About Us" fluid />
              </Col>
          </Row>

          <Row className="justify-content-center mt-5">
              <Col md={8}>
                  <p>
                      Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vivamus suscipit tortor eget felis porttitor volutpat. Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  </p>
                  <p>
                      Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec sollicitudin molestie malesuada. Nulla porttitor accumsan tincidunt.
                  </p>
              </Col>
          </Row>
      </Container>
  );
}

export default AboutUs;