import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaHome, FaUsers, FaShoppingCart } from 'react-icons/fa';
import "./AdminDashboard.css";


const AdminDashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if a user is signed in
    const user = localStorage.getItem("User Id");
    const userPrivilege = localStorage.getItem("User Privilege")

    if (!user || userPrivilege !== "admin") {
      // If not signed in, redirect to access denied
      navigate("/access-denied");
    }
    // If signed in and admin, user has access to the admin dashboard
  }, [navigate]);

  return (
    <div className="dashboard-container">
      <div className="sidebar">
        <ul>
          <li>
            <Link to="/">
              <FaHome /> Home
            </Link>
          </li>
          <li>
            <Link to="/users">
              <FaUsers /> Users
            </Link>
          </li>
          <li>
            <Link to="/get-products">
              <FaShoppingCart /> Products
            </Link>
          </li>
        </ul>
      </div>
      <div className="content">
        <h2>Welcome to the Admin Dashboard</h2>
        {/* Add additional dashboard content */}
      </div>
    </div>
  );
};

export default AdminDashboard;
