import React from "react";
import "../../index.css";
import "./Carousel.css";
import Carousel from "react-bootstrap/Carousel";
import blueberries from "../../images/blueberries.jpeg";

function CarouselComponent() {
  return (
    <React.Fragment>
      <Carousel slide={false} className="carousel slide mb-6 bg-dark" id="carousel-card">
        <Carousel.Item>
          <img src={blueberries} text="First slide" alt="" />
          <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={blueberries} text="Second Slide" alt="" />
          <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img src={blueberries} text="First slide" alt="" />
          <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </React.Fragment>
  );
}

export default CarouselComponent;
